<template>
	<div class="outer-wrapper">
		<div class="auth">

			<div class="inner-wrapper">

				<div class="heading-section">
					<h1 class="heading3">Create an account</h1>
				</div>
			
				<form @submit.prevent="handleSubmit">
					<div class="form-group">
						<label for="firstName">First Name</label>
						<input type="text" v-model="newUser.firstName" name="firstName" class="form-control" :class="{ 'is-invalid': submitted && errors.has('firstName') }" />
						<div v-if="submitted && errors.has('firstName')" class="invalid-feedback">{{ errors.get('firstName')[0] }}</div>
					</div>
					<div class="form-group">
						<label for="lastName">Last Name</label>
						<input type="text" v-model="newUser.lastName" name="lastName" class="form-control" :class="{ 'is-invalid': submitted && errors.has('lastName') }" />
						<div v-if="submitted && errors.has('lastName')" class="invalid-feedback">{{ errors.get('lastName')[0] }}</div>
					</div>
					<div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
					<div class="form-group btn-container">
						<button class="button btn-primary" >Continue</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router';

export default {
	data () {
		return {
			submitted: true,
			errors: new Map(),
		}
	},
	computed: {
		...mapState('account', ['status', 'newUser']),
		...mapState({
				alert: state => state.alert,
			}),
	},
	methods: {
		...mapActions('account', ['register']),
		handleSubmit(e) {
			// Redirect to the next register screen, if validation is met.
			if(this.checkForm()) {
				router.push({ name: "Register Email", query: { redirect: this.$route.query.redirect }});
			}
		},
		checkForm() {
			this.errors = new Map();
			if(!this.newUser.firstName || this.newUser.firstName == null) {
				this.errors.set('firstName', ['First name is required']);
			}
			if(!this.newUser.lastName || this.newUser.lastName == null) {
				this.errors.set('lastName', ['Last name is required']);
			}

			if (!this.errors.size > 0) {
				return true;
			}
			return false;
			e.preventDefault();
		}
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: { name: 'Login', query: { redirect: this.$route.query.redirect }},
			},
			right: {
				type: 'none',
			},
		});
	},
	mounted () {
        // reset login status
		this.logout();
	},
};
</script>
